// Imports => React
import clsx from 'clsx';
import { memo, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import ReactTooltip from 'react-tooltip';

// Imports => Constants
import {
  DATETIME_FORMATS,
  ICONS,
  KEYS,
  SIZES,
  THEMES,
  TYPES,
} from '@constants';

// Imports => Utilities
import {
  AcCapitalize,
  AcFormatCountry,
  AcFormatDate,
  AcFormatInternalURI,
  AcFormatRole,
  AcGetClosestElement,
  AcGetEquipmentIcon,
  AcGetPagination,
  AcIsArray,
  AcIsObject,
  AcIsSet,
  AcIsString,
  AcIsUndefined,
  AcUUID,
} from '@utils';

// Imports => Components
import AcTableContextualMenu from '@components/ac-table-contextual-menu/ac-table-contextual-menu.web';

// Imports => Atoms
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcRipple = loadable(() => import('@atoms/ac-ripple/ac-ripple.web'));
const AcTableCheckboxWeb = loadable(() =>
  import('@atoms/ac-table-checkbox/ac-table-checkbox.web')
);

const _CLASSES = {
  TABLE: {
    MAIN: 'ac-table',
    CLONE: 'ac-table--clone',
    HORIZONTAL_SCROLL: 'ac-table--horizontal-scroll',
    WRAP: {
      MAIN: 'ac-table-wrap',
    },
    WRP: 'ac-table-wrp',
    WIDE: 'ac-table-wrp--wide',
    HEAD: 'ac-table__head',
    BODY: 'ac-table__body',
    FOOTER: 'ac-table__footer',
    ROW: 'ac-table__row',
    CELL: {
      MAIN: 'ac-table__cell',
      HEAD: 'ac-table__cell--head',
      SORTABLE: 'ac-table__cell--sortable',
      SORTED: {
        MAIN: 'ac-table__cell--sorted',
        ASCENDING: 'ac-table__cell--ascending',
        DESCENDING: 'ac-table__cell--descending',
      },
      ACTION: 'ac-table__cell--action',
      GROW: 'ac-table__cell--grow-',
      SIZE: 'ac-table__cell--size-',
      CONTENT: 'ac-table__cell__content',
      CONTENT_OVERFLOW: 'ac-table__cell__content--overflow',
      CONTENT_WRP: 'ac-table__cell__content-wrp',
    },
    SORT: {
      MAIN: 'ac-table__sort',
      WRP: 'ac-table__sort-wrp',
      ICON: 'ac-table__sort__icon',
      ASCENDING: 'ac-table__sort__icon--ascending',
      DESCENDING: 'ac-table__sort__icon--descending',
    },
    PAGINATION: {
      MAIN: 'ac-table__pagination',
      TOTAL: 'ac-table__pagination__total',
      CONTROLS: {
        MAIN: 'ac-table__pagination__controls',
        ACTION: 'ac-table__pagination__action',
        PREVIOUS: 'ac-table__pagination__action--previous',
        NEXT: 'ac-table__pagination__action--next',
        ICON: 'ac-table__pagination__icon',
      },
    },
    SCROLL: {
      VERTICAL: 'ac-table__body__scroll--vertical',
      HORIZONTAL: 'ac-table__body__scroll--horizontal',
    },
    PAGES: {
      NUMBERS: 'ac-table__pagination__numbers',
      NUMBER: 'ac-table__pagination__number',
      CURRENT: 'ac-table__pagination__number--current',
      SEPARATOR: 'ac-table__pagination__number--separator',
    },
  },
};

let timer = null;

const AcTablePageNumber = memo(({ num, current, callback, className }) => {
  const handleClick = (event) => {
    if (callback) callback();
  };

  return (
    <span className={className} onClick={handleClick}>
      {num}
      <AcRipple theme={THEMES.WHITE} size={SIZES.SMALL} simple />
    </span>
  );
});

const AcTable = ({
  columns = [],
  rows = [],
  pagination = {
    total: 0,
    current: 0,
    size: 15,
    pages: 0,
    callback: undefined,
  },
  sortby,
  onSort,
  selection = undefined,
  onPaginate,
  onDownload,
  wide = false,
  actions = null,
  renderRowAction = () => true,
  withIcon = false,
  renderColumnData = null,
  verticalScroll = false,
  horizontalScroll = false,
  fixedColumns = null,
  wrap = false,
}) => {
  const { t } = useTranslation();
  let $table = useRef(null);
  let $tableclone = useRef(null);
  let $head = useRef(null);
  let $body = useRef(null);
  let $foot = useRef(null);

  const [tableWidth, setTableWidth] = useState(null);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    addEvents();
    // calculateCellWidths();

    return () => {
      removeEvents();
    };
  }, [pagination]);

  const handleSelectAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    selection.onSelectAll(rows);
  };

  const resetSelection = () => {
    if (!selection?.onSelectAll) return;
    if (selection?.rows?.length) selection.onSelectAll([]);
  };

  const handleRowSelect = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    selection.onRowSelect(row);
  };

  const handleItemSelected = useCallback(
    (item) => {
      if (!item) return false;
      return selection.isRowSelected(item);
    },
    [selection?.rows]
  );

  const calculatedNumberOfColumns = useMemo(() => {
    if (!AcIsSet(columns)) return null;

    const collection = columns.slice();
    const len = collection?.length;
    let n = 0;
    let result = 0;

    if (AcIsSet(selection)) result++;

    for (n; n < len; n++) {
      const item = collection[n];

      if (item.visible) result++;
    }

    if (AcIsSet(actions) && actions?.length > 0) result++;

    return result;
  }, [columns, actions]);

  const addEvents = () => {
    document.addEventListener('keyup', handleKeyUp, { passive: true });
  };

  const removeEvents = () => {
    if (timer) clearTimeout(timer);
    document.removeEventListener('keyup', handleKeyUp, { passive: true });
  };

  const handleKeyUp = (event) => {
    if (!AcIsSet(event)) return;
    if (event && event.persist) event.persist();
    const key = event.key || event.which;

    const $active_element = document.activeElement;

    const $inputs = ['input', 'select', 'button', 'textarea', 'form'];

    if (key) {
      if (
        $active_element &&
        $inputs.indexOf($active_element.tagName.toLowerCase()) === -1
      ) {
        switch (key) {
          case 'ArrowLeft':
          case 37:
            handlePrevious();
            break;

          case 'ArrowRight':
          case 39:
            handleNext();
            break;

          default:
        }
      }
    }
  };

  const handleDownload = (event, link, row) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    if (!AcIsSet(link) || !AcIsSet(link.entity)) return;

    let options = {};

    switch (link.entity) {
      case 'pdf-record':
      case 'pdf':
        options.pdf = true;
        break;
      case 'csv-record':
      case 'csv':
        options.csv = true;
        break;
      case 'xls-record':
      case 'xls':
      case 'excel-record':
      case 'xlsx':
        options.xls = true;
        break;

      default:
    }

    if (onDownload) onDownload(link, options, row);
  };

  const handleSort = (event, column) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    if (!AcIsSet(column)) return;
    if (!AcIsSet(column.sortable)) return;
    if (!AcIsSet(column.key)) return;

    if (onSort) onSort(column.key);
  };

  const handlePageClick = useCallback(
    (num) => {
      if (!AcIsSet(pagination)) return;
      if (onPaginate && num !== pagination.current_page) {
        resetSelection();
        onPaginate(num, $table?.current);
      }
    },
    [onPaginate, pagination]
  );

  const handlePrevious = useCallback(
    (event) => {
      if (!AcIsSet(pagination)) return;
      if (pagination.current_page === 1) return;

      const current = pagination.current_page;
      const previous = current - 1;

      if (onPaginate && previous >= 1) {
        resetSelection();
        onPaginate(previous);
      }
    },
    [onPaginate, pagination]
  );

  const handleNext = useCallback(
    (event) => {
      if (!AcIsSet(pagination)) return;
      if (pagination.current_page === pagination.last_page) return;

      const current = pagination.current_page;
      const next = current + 1;

      if (onPaginate && next <= pagination.last_page) {
        resetSelection();
        onPaginate(next);
      }
    },
    [onPaginate, pagination]
  );

  const calculateCellWidths = useCallback(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      const $tablebody = $body && $body.current;

      if (!AcIsSet($tablebody)) {
        calculateCellWidths();
        return;
      }

      const $rows = $tablebody.querySelectorAll(`.${_CLASSES.TABLE.ROW}`);

      const len = $rows?.length;
      let n = 0;

      let cell_widths = [];

      for (n; n < len; n++) {
        const $row = $rows[n];
        const rowrect = $row.getBoundingClientRect();
        const $cells = $row.querySelectorAll(`.${_CLASSES.TABLE.CELL.MAIN}`);

        const clen = $cells?.length;
        let x = 0;
        let max_cell_width = rowrect.width / clen / 10;

        for (x; x < clen; x++) {
          const $cell = $cells[x];
          const rect = $cell.getBoundingClientRect();

          let width = rect.width / 10;
          width = width > max_cell_width ? max_cell_width : width;

          if (!AcIsSet(cell_widths[x])) {
            cell_widths.push(width);
          } else if (cell_widths[x] && width > cell_widths[x]) {
            cell_widths[x] = width;
          }
        }
      }

      window.requestAnimationFrame(() => {
        setWidths(cell_widths);
      });

      return cell_widths;
    }, 50);
  }, [$body, columns]);

  const setWidths = useCallback(
    (widths) => {
      const $tablebody = $body && $body.current;
      const $tablehead = $head && $head.current;

      if (!AcIsSet($tablebody) || !AcIsSet($tablehead)) return;

      const $hcells = $tablehead.querySelectorAll(
        `.${_CLASSES.TABLE.CELL.MAIN}`
      );
      const $rows = $tablebody.querySelectorAll(`.${_CLASSES.TABLE.ROW}`);

      const hlen = $hcells?.length;
      let b = 0;

      for (b; b < hlen; b++) {
        const $hcell = $hcells[b];

        if (AcIsSet(widths[b])) {
          $hcell.style.width = `${widths[b]}rem`;
        }
      }

      const len = $rows?.length;
      let n = 0;

      for (n; n < len; n++) {
        const $row = $rows[n];
        const $cells = $row.querySelectorAll(`.${_CLASSES.TABLE.CELL.MAIN}`);

        const clen = $cells?.length;
        let x = 0;

        for (x; x < clen; x++) {
          const $cell = $cells[x];

          if (AcIsSet(widths[x])) $cell.style.width = `${widths[x]}rem`;
        }
      }

      return widths;
    },
    [$head, $body]
  );

  const calculateWidth = (event) => {
    if (!AcIsSet(event) || !AcIsSet(event.target)) return;
    if (event.persist) event.persist();

    const target = event.target.classList.contains(_CLASSES.TABLE.CELL.CONTENT)
      ? event.target
      : AcGetClosestElement(event.target, `.${_CLASSES.TABLE.CELL.CONTENT}`);

    if (!AcIsSet(target)) return;

    let width = (target.scrollWidth + 1) / 10;

    width = width > 50 ? 50 : width;
    if (horizontalScroll) width = 50;
    target.style.maxWidth = `${width}rem`;
  };

  const resetWidth = (event) => {
    if (!AcIsSet(event) || !AcIsSet(event.target)) return;
    if (event.persist) event.persist();

    const target = event.target.classList.contains(_CLASSES.TABLE.CELL.CONTENT)
      ? event.target
      : AcGetClosestElement(event.target, `.${_CLASSES.TABLE.CELL.CONTENT}`);

    if (!AcIsSet(target)) return;

    target.style.maxWidth = '';
  };

  const getTableCellContentClassNames = useCallback((key) => {
    return clsx(
      _CLASSES.TABLE.CELL.CONTENT,
      key === 'priority' && _CLASSES.TABLE.CELL.CONTENT_OVERFLOW
    );
  }, []);

  const getTableCellContentWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.CELL.CONTENT_WRP);
  }, []);

  const getTableActionCellClassNames = useMemo(() => {
    return clsx(
      _CLASSES.TABLE.CELL.MAIN,
      _CLASSES.TABLE.CELL.ACTION,
      `${_CLASSES.TABLE.CELL.SIZE}1`
    );
  }, []);

  const getTableBodyCellClassNames = useCallback((_size) => {
    return clsx(
      _CLASSES.TABLE.CELL.MAIN,
      _size && `${_CLASSES.TABLE.CELL.SIZE}${_size}`
    );
  }, []);

  const getTableHeadSortIconClassNames = useCallback((direction) => {
    return clsx(
      _CLASSES.TABLE.SORT.ICON,
      direction === KEYS.ASCENDING && _CLASSES.TABLE.SORT.ASCENDING,
      direction === KEYS.DESCENDING && _CLASSES.TABLE.SORT.DESCENDING
    );
  }, []);

  const getTableHeadSortIconWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.SORT.MAIN, _CLASSES.TABLE.SORT.WRP);
  }, []);

  const getTableHeadActionCellClassNames = useMemo(() => {
    return clsx(
      _CLASSES.TABLE.CELL.MAIN,
      _CLASSES.TABLE.CELL.HEAD,
      _CLASSES.TABLE.CELL.ACTION,
      `${_CLASSES.TABLE.CELL.SIZE}1`
    );
  }, []);

  const getTableHeadCellClassNames = useCallback(
    (_sortable, _sortkey, _size) => {
      const _sorted = sortby && _sortkey === sortby.field;
      const _direction = sortby && _sorted && sortby.direction;

      return clsx(
        _CLASSES.TABLE.CELL.MAIN,
        _CLASSES.TABLE.CELL.HEAD,
        _sortable && _CLASSES.TABLE.CELL.SORTABLE,
        _sorted && _CLASSES.TABLE.CELL.SORTED.MAIN,
        _direction === KEYS.ASCENDING && _CLASSES.TABLE.CELL.SORTED.ASCENDING,
        _direction === KEYS.DESCENDING && _CLASSES.TABLE.CELL.SORTED.DESCENDING,
        _size && `${_CLASSES.TABLE.CELL.SIZE}${_size}`
      );
    },
    [sortby, pagination]
  );

  const getTableRowClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.ROW);
  }, []);

  const getTablePaginationControlsPageNumberClassNames = useCallback(
    (current = false, separator = false) => {
      return clsx(
        _CLASSES.TABLE.PAGES.NUMBER,
        current && _CLASSES.TABLE.PAGES.CURRENT,
        separator && _CLASSES.TABLE.PAGES.SEPARATOR
      );
    },
    []
  );

  const getTablePaginationControlsPageNumbersClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.PAGES.NUMBERS);
  }, []);

  const getTablePaginationControlsActionIconClassNames = useCallback(() => {
    return clsx(_CLASSES.TABLE.PAGINATION.CONTROLS.ICON);
  }, []);

  const getTablePaginationControlsActionClassNames = useCallback(
    (direction) => {
      return clsx(
        _CLASSES.TABLE.PAGINATION.CONTROLS.ACTION,
        direction === KEYS.PREVIOUS &&
          _CLASSES.TABLE.PAGINATION.CONTROLS.PREVIOUS,
        direction === KEYS.NEXT && _CLASSES.TABLE.PAGINATION.CONTROLS.NEXT
      );
    },
    []
  );

  const getTablePaginationControlsClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.PAGINATION.CONTROLS.MAIN);
  }, []);

  const getTablePaginationTotalClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.PAGINATION.TOTAL);
  }, []);

  const getTablePaginationClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.PAGINATION.MAIN);
  }, []);

  const getTableFooterClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.FOOTER);
  }, []);

  const getTableBodyClassNames = useMemo(() => {
    return clsx(
      _CLASSES.TABLE.BODY,
      verticalScroll && _CLASSES.TABLE.SCROLL.VERTICAL
    );
  }, []);

  const getTableHeadClassNames = useMemo(() => {
    return clsx(_CLASSES.TABLE.HEAD);
  }, []);

  const getTableClassNames = useMemo(() => {
    const COLS =
      AcIsSet(calculatedNumberOfColumns) &&
      calculatedNumberOfColumns > 0 &&
      `${_CLASSES.TABLE.MAIN}--${calculatedNumberOfColumns}`;
    return clsx(_CLASSES.TABLE.MAIN, wrap && _CLASSES.TABLE.WRAP.MAIN, COLS);
  }, [calculatedNumberOfColumns, wrap]);

  const getTableCloneClassNames = useMemo(() => {
    const COLS =
      AcIsSet(calculatedNumberOfColumns) &&
      calculatedNumberOfColumns > 0 &&
      `${_CLASSES.TABLE.MAIN}--${calculatedNumberOfColumns}`;
    return clsx(
      _CLASSES.TABLE.MAIN,
      wrap && _CLASSES.TABLE.WRAP.MAIN,
      _CLASSES.TABLE.CLONE,
      COLS
    );
  }, [calculatedNumberOfColumns, wrap, fixedColumns]);

  const getTableWrpClassNames = useMemo(() => {
    return clsx(
      _CLASSES.TABLE.WRP,
      wide && _CLASSES.TABLE.WIDE,
      horizontalScroll && _CLASSES.TABLE.HORIZONTAL_SCROLL
    );
  }, [wide, horizontalScroll]);

  const getCellValue = useCallback((row, key, inline) => {
    if (!AcIsSet(row)) return '-';
    if (!AcIsSet(key)) return row;

    let result = null;

    if (AcIsObject(row)) {
      if (AcIsSet(row[key])) result = { value: row[key] };
      else {
        return '-';
      }
    } else {
      const len = row?.length;
      let n = 0;

      for (n; n < len; n++) {
        const item = row[n];
        const item_key =
          AcIsArray(item) && item?.length > 0 && AcIsSet(item[0]?.key)
            ? item[0].key
            : item?.key
            ? item.key
            : null;

        if (item_key === key) {
          result = item;
          break;
        }
      }
    }

    return result;
  }, []);

  const formatSingleValue = (input, type, format, column, row) => {
    let result = input;
    let value, name, link;

    if (AcIsObject(input)) {
      if (!AcIsUndefined(input.value) || !AcIsUndefined(input.name)) {
        value = input.value;
        name = input.name;
        link = input.link;
        if (AcIsObject(value)) {
          value = value.name || value.group || '-';
        }
        result = value || name || '-';
      }
    }

    if (column) {
      switch (column.type) {
        case 'date':
          result = AcFormatDate(input.value, null, column.meta.format);
          break;

        case 'custom':
          if (column?.meta?.format) {
            result = column?.meta?.format(input.value, row);
          }
      }
    }

    let _format = format;

    switch (type) {
      case 'date':
      case 'released_at':
      case 'available_at':
        if (!_format)
          _format = {
            from: DATETIME_FORMATS.RAW_DATE,
            to: DATETIME_FORMATS.DATE,
          };
        result = AcFormatDate(result, _format.from, _format.to);
        break;
      case 'triggered_at':
      case 'timestamp':
        if (!_format)
          _format = {
            from: null,
            to: DATETIME_FORMATS.DATETIME_WITH_YEAR_AND_TIME,
          };
        result = AcFormatDate(result, _format.from, _format.to);
        break;

      case 'date_start':
      case 'date_end':
        if (!_format)
          _format = {
            from: DATETIME_FORMATS.RAW_DATE,
            to: DATETIME_FORMATS.DATE,
          };
        result = AcFormatDate(result, _format.from, _format.to);
        break;

      case 'piling_date':
        if (!_format)
          _format = {
            from: DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR_UTC,
            to: DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR,
          };
        result = AcFormatDate(result, _format.from, _format.to);
        break;

      case 'role':
      case 'roles':
      case 'user_roles':
        result = AcFormatRole(result);
        break;

      case 'country_code':
        result = AcFormatCountry(result);
        break;

      default:
        break;
    }

    if (AcIsString(result)) {
      result =
        [TYPES.EMAIL, TYPES.WEBSITE].indexOf(type) === -1
          ? AcCapitalize(result)
          : result;
    }

    if (AcIsSet(link) && link.id && link.active) {
      let route = AcFormatInternalURI(link, value);
      if (
        link.entity &&
        (link.entity === 'pdf-record' ||
          link.entity === 'csv-record' ||
          link.entity === 'xls-record' ||
          link.entity === 'excel-record')
      ) {
        result = (
          <a
            href={'download'}
            key={`link-${link.id}`}
            onClick={(event) => handleDownload(event, link, row)}
          >
            {value}
          </a>
        );
      } else {
        result = (
          <Link
            key={`link-${link.id}`}
            to={route}
            className={'ac-table__cell__row'}
          >
            {value}
          </Link>
        );
      }
    }

    return result;
  };

  const formatCellValue = useCallback(
    (cell, type, format, index, row, inline) => {
      if (!cell) return '-';

      if (AcIsSet(cell) && inline === true) {
        return cell.value;
      }

      const columnIndex = index.split('-')[1];
      const column = columns[columnIndex];

      const { value } = cell;
      const tooltip =
        row && AcIsObject(row) && AcIsSet(row.tooltip) && row.tooltip;
      let result = value;

      if (AcIsArray(cell) || AcIsArray(value)) {
        const collection = AcIsArray(cell) ? cell : value;
        const len = collection?.length;
        let n = 0;
        result = [];

        for (n; n < len; n++) {
          const item = collection[n];
          const val = formatSingleValue(item, type, format, column, row);
          result.push(<div key={n}>{val}</div>);
        }
      } else {
        result = formatSingleValue(cell, type, format, column, row);

        if (withIcon && AcIsSet(format) && type === KEYS.OBJECT_NO) {
          const icon = AcGetEquipmentIcon(format);
          if (AcIsSet(icon))
            result = [
              <i
                key={`ac-icon-${AcUUID()}`}
                className={`ac-icon ac-icon--${icon}`}
              />,
              result,
            ];
        }
      }

      if (AcIsSet(tooltip) && tooltip.column === type) {
        const text = tooltip.content.replace(/"/g, '&quot;');
        const popper = `<i class="ac-icon ac-icon--information-outline" data-tip="${text}" data-html="true" data-place="right" data-class="ac-table__cell__content__tooltip"></i>`;
        result = `${popper} ${result}`;
      }

      return result;
    },
    []
  );

  const renderTableHeader = useMemo(() => {
    if (!columns) return null;

    const len = columns?.length;
    let n = 0;
    let result = [];

    let totalCellWidths = 0;

    for (n; n < len; n++) {
      const item = columns[n];

      if (item.visible) {
        if (item?.style?.['flex-basis']) {
          const cellwidth = parseInt(item?.style?.['flex-basis'], 10);
          totalCellWidths += cellwidth;
        }

        const object = (
          <div
            key={`column-${n}-${item.key}`}
            style={{ ...item.style }}
            className={getTableHeadCellClassNames(
              item.sortable,
              item.key,
              item.key === 'priority' ? 1 : '2'
            )}
            onClick={(event) => {
              if (item.sortable) handleSort(event, item);
            }}
          >
            {item.sortable && (
              <span className={getTableHeadSortIconWrpClassNames}>
                <AcIcon
                  icon={ICONS.ARROW_UP}
                  className={getTableHeadSortIconClassNames(KEYS.DESCENDING)}
                />
                <AcIcon
                  icon={ICONS.ARROW_DOWN}
                  className={getTableHeadSortIconClassNames(KEYS.ASCENDING)}
                />
              </span>
            )}
            {t(item.label)}
          </div>
        );
        result.push(object);
      }
    }

    const resetTableHeaderProps = { style: {} };

    if (totalCellWidths) {
      resetTableHeaderProps.style.width = `${totalCellWidths}rem`;
    }

    return (
      <div
        className={getTableHeadClassNames}
        ref={$head}
        {...resetTableHeaderProps}
      >
        <div className={getTableRowClassNames}>
          {selection && (
            <div style={{ paddingLeft: '3rem' }}>
              <AcTableCheckboxWeb
                position="before"
                callback={handleSelectAll}
                children=""
                checked={
                  selection?.rows?.[0] === 'all' ||
                  selection?.rows?.length == rows?.length
                }
              />
            </div>
          )}
          {result}
          {actions && <div className={getTableHeadActionCellClassNames} />}
        </div>
      </div>
    );
  }, [columns, selection, selection?.rows, fixedColumns]);

  const renderTableBody = useMemo(() => {
    if (!rows || !columns) return null;

    const { current_page } = pagination || { current_page: 1 };
    let key = `${current_page}`;

    if (sortby) {
      const { field, direction } = sortby;
      key = `${key}-${field}-${direction}`;
    }

    const len = rows?.length;
    let n = 0;
    let result = [];

    const clen = columns?.length;
    let cn = 0;

    let totalCellWidths = 0;
    let fixedCellWidths = 0;

    for (n; n < len; n++) {
      const row = rows[n];
      let nrow = [];

      cn = 0;
      for (cn; cn < clen; cn++) {
        const column = columns[cn];
        column.size = 1;

        if (column.visible) {
          const renderColData =
            (renderColumnData &&
              renderColumnData[column.key] &&
              renderColumnData[column.key](
                getCellValue(row, column.key, column.inline),
                cn + n,
                row
              )) ||
            null;

          let cell, group, value;
          value = renderColData;
          if (!value && !renderColData) {
            cell = getCellValue(row, column.key, column.inline);
            group = getCellValue(row, 'type_name');
            value = formatCellValue(
              cell,
              column.key,
              (row && row.equipment_group) || (group && group.value),
              `${n}-${cn}`,
              row,
              column.inline
            );
          }

          // extract widths from first row only
          if (n === 0) {
            if (column?.style?.['flex-basis']) {
              const cellwidth = parseInt(column?.style?.['flex-basis'], 10);
              totalCellWidths += cellwidth;
            }
          }

          if (fixedColumns && n < fixedColumns) {
            if (column?.style?.['flex-basis']) {
              const cellwidth = parseInt(column?.style?.['flex-basis'], 10);
              fixedCellWidths += cellwidth;
            }
          }

          const object = (
            <div
              key={`cell-${n}-${column.key}`}
              style={{ ...column.style }}
              className={getTableBodyCellClassNames(
                column.key === 'priority' ? 1 : '2'
              )}
            >
              {AcIsString(value) && (
                <>
                  <div
                    className={getTableCellContentWrpClassNames}
                    dangerouslySetInnerHTML={{
                      __html: `<span>${value}</span>`,
                    }}
                  />
                  <div
                    className={getTableCellContentClassNames(column.key)}
                    onMouseOver={calculateWidth}
                    onMouseOut={resetWidth}
                    dangerouslySetInnerHTML={{
                      __html: `<span>${value}</span>`,
                    }}
                  />
                </>
              )}
              {!AcIsString(value) && (
                <>
                  <div className={getTableCellContentWrpClassNames}>
                    <span>{value}</span>
                  </div>
                  <div
                    className={getTableCellContentClassNames(column.key)}
                    onMouseOver={calculateWidth}
                    onMouseOut={resetWidth}
                  >
                    <span>{value}</span>
                  </div>
                </>
              )}
            </div>
          );
          nrow.push(object);
        }
      }
      if (actions?.length) {
        renderRowAction(row) &&
          nrow.push(
            <div
              key={`cell-${n}-row-action`}
              className={getTableActionCellClassNames}
            >
              <AcTableContextualMenu
                id={`n-cm-${n}-${Math.random()}`}
                data={row}
                actions={actions}
              />
            </div>
          );
      }

      const row_element = (
        <div key={`row-${AcUUID()}`} className={getTableRowClassNames}>
          {selection && (
            <div
              style={{ paddingLeft: '3rem' }}
              className={'ac-table-cell--selectbox'}
            >
              <AcTableCheckboxWeb
                children=""
                callback={(e) => handleRowSelect(e, row)}
                value={row}
                checked={handleItemSelected(row)}
              />
            </div>
          )}
          {nrow}
        </div>
      );
      result.push(row_element);
    }

    const restTableBodyProps = { style: {} };

    if (totalCellWidths) {
      restTableBodyProps.style.width = `${totalCellWidths}rem`;
      restTableBodyProps['data-clone-width'] = `${fixedCellWidths}rem`;
      restTableBodyProps.title = 'Drag to scroll horizontally';
    }

    return (
      <div
        className={getTableBodyClassNames}
        key={key}
        ref={$body}
        {...restTableBodyProps}
      >
        {result}
      </div>
    );
  }, [
    columns,
    rows,
    pagination,
    sortby,
    selection,
    selection?.rows,
    fixedColumns,
  ]);

  const renderPageNumbers = useCallback(
    (pages, current_page) => {
      if (!AcIsSet(pages)) return null;

      const pagination = AcGetPagination(current_page, {
        range: 3,
        pages,
      });

      let result = [];

      let has_first_page = pagination.find((item) => item.num === 1);
      let has_last_page = pagination.find((item) => item.num === pages);

      if (!has_first_page) {
        result.push(
          <AcTablePageNumber
            num={1}
            key={'ac-table-page-number-1'}
            callback={() => {
              handlePageClick(1);
            }}
            className={getTablePaginationControlsPageNumberClassNames()}
          />
        );

        if (pagination[0]?.num - 1 > 1) {
          result.push(
            <span
              key={'ac-table-page-number-separator-1'}
              className={getTablePaginationControlsPageNumberClassNames(
                null,
                true
              )}
            >
              ...
            </span>
          );
        }
      }

      let n = 0;
      let len = pagination?.length;

      for (n; n < len; n++) {
        const item = pagination[n];
        result.push(
          <AcTablePageNumber
            {...item}
            key={`ac-table-page-number-${item.num}`}
            callback={() => {
              handlePageClick(item.num);
            }}
            className={getTablePaginationControlsPageNumberClassNames(
              item.current
            )}
          />
        );
      }

      if (!has_last_page) {
        if (pagination[pagination?.length - 1]?.num + 1 < pages) {
          result.push(
            <span
              key={`ac-table-page-number-separator-${pages}`}
              className={getTablePaginationControlsPageNumberClassNames(
                null,
                true
              )}
            >
              ...
            </span>
          );
        }
        result.push(
          <AcTablePageNumber
            num={pages}
            key={`ac-table-page-number-${pages}`}
            callback={() => {
              handlePageClick(pages);
            }}
            className={getTablePaginationControlsPageNumberClassNames()}
          />
        );
      }

      return result;
    },
    [pagination]
  );

  const renderTablePagination = useMemo(() => {
    if (!AcIsSet(pagination)) return null;

    const { total, current_page, last_page } = pagination;
    const current = {
      min: pagination.from || 0,
      max: pagination.to || 0,
      total: total || 0,
    };
    const total_results = `<strong>{{min}}</strong> - <strong>{{max}}</strong> out of <strong>{{total}}</strong> results`;

    const restTableFooterProps = { style: {} };

    if (
      AcIsSet(horizontalScroll) &&
      horizontalScroll === true &&
      AcIsSet(tableWidth)
    ) {
      restTableFooterProps.style.maxWidth = tableWidth;
    }

    return (
      <div
        className={getTableFooterClassNames}
        ref={$foot}
        {...restTableFooterProps}
      >
        <div className={getTableRowClassNames}>
          <div className={getTablePaginationClassNames}>
            <div
              className={getTablePaginationTotalClassNames}
              key={JSON.stringify(current)}
              dangerouslySetInnerHTML={{
                __html: t(total_results, current),
              }}
            />

            <div className={getTablePaginationControlsClassNames}>
              <div
                className={getTablePaginationControlsActionClassNames(
                  KEYS.PREVIOUS
                )}
                disabled={current_page === 1}
                onClick={handlePrevious}
              >
                <AcIcon
                  icon={ICONS.ARROW_LEFT}
                  className={getTablePaginationControlsActionIconClassNames}
                />
              </div>

              <div className={getTablePaginationControlsPageNumbersClassNames}>
                {renderPageNumbers(last_page, current_page)}
              </div>

              <div
                className={getTablePaginationControlsActionClassNames(
                  KEYS.NEXT
                )}
                disabled={current_page === last_page}
                onClick={handleNext}
              >
                <AcIcon
                  icon={ICONS.ARROW_RIGHT}
                  className={getTablePaginationControlsActionIconClassNames}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [pagination, tableWidth, horizontalScroll]);

  useEffect(() => {
    const calulcateTableWidthWhenHorizontalScroll = () => {
      if (AcIsSet($table?.current)) {
        const calculatedTableWidth = parseInt($table.current.clientWidth, 10);
        if (AcIsSet($foot?.current) && calculatedTableWidth !== tableWidth) {
          setTableWidth(`${calculatedTableWidth / 10}rem`);
        }
      }
    };

    window.addEventListener('resize', calulcateTableWidthWhenHorizontalScroll, {
      passive: true,
    });
    calulcateTableWidthWhenHorizontalScroll();

    if (AcIsSet($table?.current)) {
      const slider = $table.current;
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [horizontalScroll, $table?.current, $foot?.current]);

  const getTooltipProps = useMemo(() => {
    if (!horizontalScroll) return {};

    return {
      'data-tip': 'Drag to scroll horizontally',
      'data-html': true,
      'data-class': 'ac-table--horizontal-scroll__tooltip',
    };
  }, [horizontalScroll]);

  return (
    <div className={getTableWrpClassNames} {...getTooltipProps}>
      <div className={getTableClassNames} ref={$table}>
        {renderTableHeader}
        {renderTableBody}
        {renderTablePagination}
      </div>
      {fixedColumns && (
        <div className={getTableCloneClassNames} ref={$tableclone}>
          {renderTableHeader}
          {renderTableBody}
          {renderTablePagination}
        </div>
      )}
      <ReactTooltip />
    </div>
  );
};

export default memo(AcTable);
